import React from "react";
import "./Privacystyle.css";
export default function terms() {
  return (
    <>
      <div>
        <div className="Privacy_policies">
          <h1>Terms & Conditions</h1>
          <div class="solid"></div>
          <p>
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying on
            meaningful content. Lorem ipsum may be used as a placeholder before
            final copy is available. It is also used to temporarily replace text
            in a process called greeking, which allows designers to consider the
            form of a webpage or publication, without the meaning of the text
            influencing the design. Lorem ipsum is typically a corrupted version
            of De finibus bonorum et malorum, a 1st-century BC text by the Roman
            statesman and philosopher Cicero, with words altered, added, and
            removed to make it nonsensical and improper Latin. Versions of the
            Lorem ipsum text have been used in typesetting at least since the
            1960s, when it was popularized by advertisements for Letraset
            transfer sheets.Lorem ipsum was introduced to the digital world in
            the mid-1980s, when Aldus employed it in graphic and word-processing
            templates for its desktop publishing program PageMaker. Other
            popular word processors, including Pages and Microsoft Word, have
            since adopted Lorem ipsum as have many LaTeX packages web content
            managers such as Joomla! and WordPress, and CSS libraries such as
            Semantic UI
          </p>
          <p>
            <b>Scope of application</b> Users can use company services in a
            variety of ways, including communicating with others, creating new
            content, and so on. Collection and use of personal information
            collected to receive additional services (advertising, payment
            services, etc.) from related companies (hereinafter referred to as
            "supplementary services") of collected personal information and
            services for smooth use and safety of this service Range. ※ For
            users who use supplementary services other than common functions,
            the Company may provide supplementary personal information
            processing policies or supplement of personal information processing
            policies for supplementary services.
          </p>

          <p>
            <b>Information about your account</b> When you create a service
            account to use the service ("Account"), the company collects your
            phone number. It uses ID (random unique value creation), mobile
            phone number, email address, gender, and IMEI number (device value)
            to prevent unauthorized use or unauthorized service use (or abuse).
            In addition, in order to provide users with important information
            about the service (such as handling complaints), the company may use
            the mobile phone number of the user who collected it.
          </p>
        </div>
      </div>
    </>
  );
}
